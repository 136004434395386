window.addEventListener('load', () => {
    menu();
});

window.addEventListener('resize', () => {
});

window.addEventListener('scroll', () => {
});

const body = document.querySelector('body');

function menu() {
    const header = body.querySelector('.header-wrapper');
    const burger = header.querySelector('.burger');
    const close = header.querySelector('.close');

    burger.addEventListener('click', ()=>{
        header.classList.add('open');
    });

    close.addEventListener('click', ()=>{
        header.classList.remove('open');
    });
}